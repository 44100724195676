@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Open+Sans:wght@300&family=Roboto:wght@400;500&display=swap');

@layer base{
    body{
        @apply font-[Lato]
    }
    li{
        color: white;
        @apply px-4;
        @apply cursor-pointer;
    }
}
.mobile-menu li{
    @apply py-6;
    @apply text-4xl;
}

.contact:hover{
    color: #1e3751;
    background-color: azure !important;
}